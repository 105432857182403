import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Html from "../components/html"


const Presentation = ({ content }) => (


  <div className="relative bg-red-50 pt-8 pb-10 px-4 sm:px-6 lg:pt-12 lg:pb-7 lg:px-8">
    <div className="absolute inset-0">
      <div className="h-1/3 sm:h-2/3"></div>
    </div>
    <div className="relative max-w-7xl mx-auto">
      <div className="text-center">
        <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
          {content.title}
        </h2>
      </div>
      <Html className="mt-12 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 lg:max-w-none" content={content.description}/>
    </div>
  </div>
);

Presentation.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Presentation;